@import './vars.sass';
*:focus {
  @extend %focus-ring; }

main {
  padding-top: 75px;
  min-height: calc( 100vh - 200px);
  > .container-fluid {
    max-width: $container-max-width;
    padding: 0; }
  @media (min-width: $md) {
    padding-top: 100px;
    margin: 0 3rem; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

h1 {
  font-size: 3.5rem;
  font-weight: 400; }

legend {
  font-weight: 400; }

a {
  color: $blue; }

button {
  &:focus {
    box-shadow: none !important;
    outline: 2px solid #012169 !important;
    outline-offset: 2px !important; } }

.border-danger {
  border: solid 2px $red !important; }
.border-success {
  border: solid 2px $green !important; }

.inline-button {
  background-color: transparent !important;
  font-size: 1rem !important;
  line-height: 1; }

.preview-label {
  font-weight: 600; }

.card-title {
  font-size: 150%;
  font-weight: 400; }

.magenta-text {
  color: magenta;
  font-size: 2rem;
  margin: 0.5rem 3rem; }
