@import '../../../vars.sass';
.btn {
  min-width: 44px;
  min-height: $input-height;
  font-size: $input-font-size;
  font-weight: 400;
  border-radius: 0;
  border: solid 2px $dark-blue;
  @extend %blue-outline;

  &.block {
    display: block;
    width: 100%; }

  &.btn-primary {
    &.blue {
      background: $dark-blue;
      border-color: transparent;
      color: $white;
      fill: $white;
      &:hover, &:focus {
        color: $white;
        fill: $white;
        text-decoration: none;
        background: lighten($dark-blue, 10%); } }

    &.red-outline {
      background: $white;
      border-color: $red;
      color: $red;
      fill: $red;
      &:hover, &:focus {
        background: $red;
        color: $white;
        fill: $white; } }

    &.red {
      color: $white;
      fill: $white;
      background: $red;
      border-color: transparent;
      &:hover, &:focus {
        background-color: darken($red, 10%); } }

    &.button-link {
      display: block;
      border-radius: 0;
      padding: 0;
      font-size: 1rem;
      background-color: transparent;
      border-color: transparent;
      color: $blue;
      font-weight: 100;
      &:hover, &:focus {
        text-decoration: underline; }

      &.sm {
        padding: 0 1em;
        line-height: 1.97em; } }

    &.transparent-btn {
      border: none;
      background-color: transparent;
      &:hover, &:focus {
        background-color: transparent;
        color: inherit;
        border: none; } } }

  &.btn-link {
    padding: 0;
    vertical-align: baseline;
    border-width: 0;
    font-size: $p-size;
    color: $blue;
    padding: 0;
    &:focus, &:hover {
      text-decoration: underline;
      background: transparent;
      color: $blue; }

    &.btn {
      &:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px; } } } }
