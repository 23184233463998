@import '../../../vars.sass';
.global-footer {
  background-color: $dark-blue;
  color: $white;
  a {
    font-weight: 400;
    color: $white; }
  padding: 1.23rem 0;
  margin-top: 3rem;
  .container-fluid {
    padding: 0;
    max-width: $container-max-width;
    p {
      margin: 0; } }
  .privacy-choices-container {
    white-space: nowrap;
    button.link {
      color: #fff !important;
      display: inline;
      font-weight: unset;
      min-height: unset;
      text-decoration: underline;
      &:hover {
        text-decoration: none; }
      &:focus {
        outline: 2px solid #fff !important;
        outline-offset: 2px;
        text-decoration: none; } }
    .pill-icon {
      height: 0.75rem;
      width: auto; } } }
