@import '../../../../vars.sass';
.base-modal {
  @media (min-width: $sm) {
    max-width: 700px; }
  .base-modal-header {
    display: flex;
    justify-content: flex-end;
    & > * {
      min-height: 44px;
      svg {
        height: 16px;
        width: 16px;
        fill: $dark-blue; } } }
  .modal-content {
    border-radius: 0;
    background-color: $light-grey;
    h1 {
      outline: none;
      &:focus-visible {
        outline: 2px solid #012169;
        outline-offset: 2px; } }
    ul.dashed-list {
      list-style-type: none;
      > li {
        text-indent: -5px;
        &:before {
          content: "\2014";
          position: absolute;
          margin-left: -20px; } } }
    ul.columns {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      @media (min-width: $sm) {
        columns: 6;
        -webkit-columns: 6;
        -moz-columns: 6; } } }

  .modal-footer {
    border-top: none; }
  .base-modal-title-container {
    display: flex;
    align-items: center;
    img {
      width: 35px; }
    h1 {
      font-size: 1.7rem; } } }
