@import '../../../vars.sass';
$header-bg-color: $white;
$mobile-protected-bg-color: $light-grey;

.global-header {
  box-shadow: 0 2px 13px rgba(0,0,0,.25);
  display: flex;
  flex-direction: column;
  background-color: $header-bg-color;
  position: relative;
  .global-nav-item {
    position: relative;
    padding: 0.23em 1.23em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    @media (min-width: $md) {
      margin-left: 0.75em; }
    a {
      height: 100%;
      display: block;
      padding: 5px 0;
      color: inherit;
      @media (min-width: $md) {
        &.active {
          &:after {
            @include bar-highlight($red); } } } } }
  .protected-navigation {
    background-color: $mobile-protected-bg-color;
    padding: 0px 15px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        min-height: 0;
        font-size: 1rem;
        color: inherit; } } }
  .main-navigation-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .main-nav-container-collapse-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0;
      .close-x {
        * {
          fill: $red; } } }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      @media (min-width: $md) {
        position: relative;
        bottom: 0;
        display: flex;
        justify-content: space-between; } }

    .boa-logo-link {
      max-width: 250px; } }

  .collapse, .collapsing {
      width: 100%;
      box-shadow: 0 4px 7px rgba(0,0,0,.15);
      background-color: $light-grey;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        .last-p-route {
          border-bottom: solid 1px $dark-grey; } } } }
