@font-face {
	font-family: 'Connections';
	font-style: normal;
	font-weight: 100;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/ConnectionsLight.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: italic;
	font-weight: 100;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/ConnectionsLight-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: normal;
	font-weight: 400;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/Connections.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: italic;
	font-weight: 400;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/Connections-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: normal;
	font-weight: 600;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/ConnectionsMedium.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: italic;
	font-weight: 600;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/ConnectionsMedium-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: normal;
	font-weight: 900;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/Connections-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Connections';
	font-style: italic;
	font-weight: 900;
	src: local('E2C8D004-883C-4204-8AC4-1C7AAD8C8429'), url('./woffs/Connections-BoldItalic.woff') format('woff');
}
