$margin-value: 10px;
.arrow-left-icon, .arrow-right-icon {
  height: 17px;
  width: 17px; }

.arrow-left-icon {
  margin-right: $margin-value; }

.arrow-right-icon {
  margin-left: $margin-value; }
