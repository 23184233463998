@import '../../../vars.sass';
.anchor {
  &.nav-util-link {
    border-radius: 3px;
    letter-spacing: 0.4px;
    padding: 32px 4px 0 0;
    font-size: 0.875rem;
    display: block;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none; }

    @media (min-width: 768px) {
      display: block;
      padding: 3px 15px 3px 38px; }

    @media (min-width: 1024px) {
        font-size: 1rem; } }


  &.block {
    display: block; }

  &.button-link {
    text-align: center;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: $p-size;
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    height: auto;
    &:hover, &:focus {
      color: $white;
      text-decoration: none; }

    &.blue-outline {
      @extend %blue-outline; } }

  &.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none; } }

