@import '~bootstrap/scss/bootstrap.scss';
@import './vars.sass';
html {
  font-size: 18px; }

body {
  font-family: "Connections", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-variant-ligatures: none;
  font-weight: 100; }
