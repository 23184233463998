@import '../../../vars.sass';

.card {
  background-color: $light-grey;
  border-radius: 0;
  border-color: $light-grey;
  &.white {
    background-color: $white;
    border-color: $white; }
  &.no-d-padding {
    .card-body {
      @media (min-width: $container-max-width) {
        padding-left: 0;
        padding-right: 0; } } } }

