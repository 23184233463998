// colors
$white: #fff;
$dark-grey: #868686;
$dark-grey-font: #646464;
$light-grey: #F5F5F5;
$red: #E31837;
$green: #279F00;
// red from color picker #E21736
// for check mark icon
$green: #279F00;
$light-red: #F3D0D6;
$bg-color-header: #333;
$light-blue: #3575CE;
$blue: #0052C2;
//color-picker-blue -  #012169
$dark-blue: #012169;
$white: #fff;
$red: #E31837;
$input-border-color: #ced4da;


$focus-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);


// dimensions
$input-height: 50px;
$input-font-size: 1.25rem;
$h2-size: 3rem;
$h2-size-2: 2rem;
$h2-size-3: 30px;
$p-size: 1.23rem;
$gray-light: #EEF2F2;
$container-max-width: 930px;

// breakpoints
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

%focus-ring {
  outline: 0;
  box-shadow: $focus-box-shadow; }

@import './sassHelpers/_mixins.sass';
@import './sassHelpers/_button-styles.sass';
